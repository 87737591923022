import './style.css';
function Error(){
    return(
        <div id="main-404">
            <div class="fof">
                <h1>Error 404</h1>
                <div>
                    <a href="/" className='btn btn-primary'>Return to Home</a>
                </div>
            </div>
        </div>
    )
}

export default Error;